import fallbackRender from './pages/error-boundary-fallback';
import Router from './pages/router/Router';
import {ErrorBoundary} from 'react-error-boundary';

const App = () => {

  return (<ErrorBoundary fallbackRender={fallbackRender} onError={(error)=>{console.error(error)}}><Router /></ErrorBoundary>);
};

export default App;
