import { Button } from 'reactstrap';

export default function fallbackRender({ error, resetErrorBoundary }) {
  return (
    <div
      role="alert"
      className="d-flex flex-wrap h-100 w-100 flex-column justify-content-center align-items-center text-center p-3"
      style={{
        minHeight: '100vh', // Ensures the container fills the viewport
        boxSizing: 'border-box' // Ensures padding doesn't affect the overall size
      }}
    >
      <div className="d-flex flex-wrap align-items-center justify-content-center mb-3">
        <p className="h2 h-md4 me-2">Something went wrong:</p>
        <pre
          className="h3 h-md5"
          style={{
            color: 'red',
            whiteSpace: 'pre-wrap', // Wraps long error messages
            wordBreak: 'break-word' // Prevents overflow
          }}
        >
          {error.message}
        </pre>
      </div>
      <Button
        type="button"
        color="primary"
        size="lg"
        className="mt-2"
        onClick={resetErrorBoundary}
      >
        Reset App
      </Button>
    </div>
  );
}
